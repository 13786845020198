import { overviewOptions } from "utils/helper/states";
import { Country } from "utils/types/address";
import {
  AppSettings,
  Currencies,
  IpInfo,
  OverviewData,
  SiteSettings,
  Currency,
} from "utils/types/app";
import { createSlice } from "@reduxjs/toolkit";
import { VendorPolicy } from "utils/types/vendorpolicies";
import { Overview } from "utils/types/user";
export type SearchItem = {
  title: string;
  uri: string;
};

export type DataState = {
  readonly app_settings: AppSettings | null;
  readonly site_settings: SiteSettings | null;
  readonly location: IpInfo | null;
  readonly isLoading: boolean;
  readonly isSidebarSm: boolean;
  readonly overviewFilter: string;
  readonly overview: Overview | null;
  readonly search_history: SearchItem[] | null;
  readonly currencies: Currencies | null;
  readonly countries: Country[] | null;
  readonly policies: VendorPolicy[] | null;
  currency: Currency | null;
};

const INITIAL_STATE: DataState = {
  app_settings: null,
  site_settings: null,
  location: null,
  overviewFilter: overviewOptions[0].value,
  overview: null,
  isLoading: false,
  search_history: null,
  isSidebarSm: false,
  currencies: null,
  policies: null,
  countries: null,
  currency: null,
};
const dataSlice = createSlice({
  name: "data",
  initialState: INITIAL_STATE,
  reducers: {
    setAppSettings(state, action) {
      state.app_settings = action.payload;
    },
    setPolicies(state, action) {
      state.policies = action.payload;
    },
    setSiteSettings(state, action) {
      state.site_settings = action.payload;
    },
    setUserLocation(state, action) {
      state.location = action.payload;
    },
    setOverviewOption(state, action) {
      state.overviewFilter = action.payload;
    },
    setOverview(state, action) {
      state.overview = action.payload;
    },
    setToggleSideBarSm(state) {
      state.isSidebarSm = !state.isSidebarSm;
    },
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },

    setCurrency(state, action) {
      state.currency = action.payload;
    },
    addToSearchHistory(state, action) {
      const newSearchItem = action.payload;
      state.search_history =
        state.search_history?.filter(
          (item) => item.title !== newSearchItem.title
        ) || [];
      state.search_history.push(newSearchItem);
      if (state.search_history.length > 3) {
        state.search_history.shift();
      }
    },
  },
});

export const {
  setAppSettings,
  setSiteSettings,
  setUserLocation,
  setOverviewOption,
  setOverview,
  addToSearchHistory,
  setToggleSideBarSm,
  setCurrencies,
  setCountries,
  setPolicies,
} = dataSlice.actions;
export default dataSlice;
