import { takeLatest, all, call, put, select } from "typed-redux-saga";

import { SetData } from "./data.actions";
import { DATA_ACTION_TYPES } from "./data.types";
import {
  setAppSettings,
  setCountries,
  setCurrencies,
  setOverview,
  setPolicies,
  setSiteSettings,
  setUserLocation,
} from "./data.slice";
import { getLocationURL, ipURL } from "utils/helper/states";
import { apiRequest } from "lib/api/apiClient";
import { AUTH_TOKEN } from "utils/helper/states";
import { AccessToken } from "utils/types/user";
import { getFromCookieProtected } from "utils/helper/helper";
import { RootState } from "store/store";

const selectDataReducer = (state: RootState) => state.data;
const isLoggedIn = () => {
  const token = getFromCookieProtected<AccessToken>(AUTH_TOKEN);
  return !!token;
};

const getUserLocation = async () => {
  try {
    const res = await fetch(ipURL);
    const data = await res.json();
    // console.log("ip", data);
    if (!data) return;

    const locFetch = await fetch(getLocationURL(data.ip));
    const location = await locFetch.json();
    // const value = JSON.stringify(location);
    return location;
  } catch (error) {
    console.error(error as Error);
  }
};

export function* fetchData() {
  try {
    const data = yield* call(apiRequest, `/api/v1/site-settings`);
    if (!data) return;
    yield* put(setSiteSettings(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* fetchOverview() {
  try {
    if (!isLoggedIn()) return;
    const state = yield* select();
    const filter = state.data.overviewFilter;
    const data = yield* call(
      apiRequest,
      `/api/v1/vendor/overview?filter=${filter}`
    );
    if (!data) return;
    yield* put(setOverview(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* fetchOverviewWithFilter(action: {
  type: string;
  payload: string;
}) {
  try {
    if (!isLoggedIn()) return;
    const data = yield* call(
      apiRequest,
      `/api/v1/vendor/overview?filter=${action.payload}`
    );
    if (!data) return;
    yield* put(setOverview(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* fetchAppData() {
  try {
    const data = yield* call(apiRequest, "/api/v1/app-settings");
    if (!data) return;
    yield* put(setAppSettings(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* fetchPolicies() {
  try {
    const data = yield* call(apiRequest, "/api/v1/vendor-policies");
    if (!data) return;
    yield* put(setPolicies(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* fetchLocation() {
  try {
    const data = yield* call(getUserLocation);
    if (!data) return;
    yield* put(setUserLocation(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* setInfoData({ payload }: SetData) {
  try {
    yield* put(setSiteSettings(payload));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* fetchCurrencies() {
  try {
    const data = yield* call(apiRequest, "/api/v1/currencies?withID=yes");
    if (!data) return;
    yield* put(setCurrencies(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* fetchCountries() {
  try {
    const data = yield* call(apiRequest, "/api/v1/countries");
    if (!data) return;
    yield* put(setCountries(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* onFetchData() {
  yield* takeLatest(DATA_ACTION_TYPES.FETCH_DATA, fetchOverview);
  yield* takeLatest(
    DATA_ACTION_TYPES.FETCH_OVERVIEW_WITH_FILTER,
    fetchOverviewWithFilter
  );
  yield* takeLatest(DATA_ACTION_TYPES.FETCH_DATA, fetchData);
  yield* takeLatest(DATA_ACTION_TYPES.FETCH_DATA, fetchAppData);
  yield* takeLatest(DATA_ACTION_TYPES.FETCH_DATA, fetchLocation);
  yield* takeLatest(DATA_ACTION_TYPES.FETCH_DATA, fetchCurrencies);
  yield* takeLatest(DATA_ACTION_TYPES.FETCH_DATA, fetchCountries);
  yield* takeLatest(DATA_ACTION_TYPES.FETCH_DATA, fetchPolicies);
}

export function* onSetData() {
  yield* takeLatest(DATA_ACTION_TYPES.SET_DATA, setInfoData);
}

export function* dataSagas() {
  yield* all([call(onFetchData), call(onSetData)]);
}
