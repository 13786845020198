import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccessToken, Transaction, User } from "utils/types/user";
import { AUTH_TOKEN } from "utils/helper/states";
import { AnalyticsData } from "utils/types/analytics";

export type UserState = {
  readonly currentUser: User | null;
  readonly transactions: Transaction[] | null;
  readonly analytics: AnalyticsData | null;
  readonly isLoading: boolean;
  readonly error: Error | null;
  readonly authToken: AccessToken | null;
  readonly isLoggedIn: boolean;
};

const INITIAL_STATE: UserState = {
  currentUser: null,
  transactions: null,
  analytics: null,
  isLoading: false,
  authToken: null,
  error: null,
  isLoggedIn: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setAuthToken(state, action) {
      state.authToken = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTransactions(state, action: PayloadAction<Transaction[]>) {
      state.transactions = action.payload;
    },
    setAnalytics(state, action: PayloadAction<AnalyticsData>) {
      state.analytics = action.payload;
    },
    logout(state) {
      state.currentUser = null;
      state.isLoggedIn = false;
      state.error = null;
      state.authToken = null;
      state.isLoading = false;
      state.transactions = null;
      localStorage.removeItem(AUTH_TOKEN);
    },
    setError(state, action) {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.error = action.payload;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
