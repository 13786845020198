export enum DATA_ACTION_TYPES {
  FETCH_DATA = "data/FETCH_DATA",
  FETCH_OVERVIEW_WITH_FILTER = "data/FETCH_OVERVIEW_WITH_FILTER",
  SET_DATA = "data/SET_DATA",
  SET_IS_LOADING = "data/SET_IS_LOADING",
}
export interface DataTypes {
  user: User;
  services: Service[];
  socials: Social[];
  stack: Stack[];
  experience: Experience[];
  education: Education[];
}

export interface Education {
  id: number;
  school: string;
  program: string;
  start_time: Date;
  end_time: Date;
}

export interface Experience {
  id: number;
  company: string;
  start_time: Date;
  end_time: Date | null;
  position: string;
  type: string;
  note: string | null;
}

export interface Service {
  name: string;
  image: string;
  slug: string;
  desc: string;
}

export interface Social {
  name: string;
  link: string;
}

export interface Stack {
  name: string;
  image: string;
  percentage: number;
}

export interface User {
  name: string;
  email: string;
  tel: string;
  profile_image: string;
  bio: string;
}
interface Actions {
  update: string;
  view: string;
}

export interface Blog {
  id: number;
  title: string;
  slug: string;
  introText: string;
  img: string;
  body: string;
  published: boolean;
  user: User;
  topic_id: number;
  actions: Actions;
}

export interface Comment {
  id: number;
  blog_id: number;
  name: string;
  email: string;
  comment: string;
  created_at: Date;
  updated_at: Date;
  children?: Comment[];
}

export interface Reply {
  id: number;
  comment_id: number;
  user_name: string;
  email: string;
  reply: string;
  created_at: Date;
  updated_at: Date;
}

export interface BlogData {
  data: Blog[] | null;
  // links: Links | null;
  // meta: Meta | null;
}
