import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { OrderItem } from "utils/types/order";

const selectOrderReducer = (state: RootState) => state.order;

export const selectOrders = createSelector(
  [selectOrderReducer],
  (order) => order.orders
);

export const selectOrderLoading = createSelector(
  [selectOrderReducer],
  (order) => order.loading
);

export const selectOrderLimit = createSelector(
  [selectOrderReducer],
  (order) => order.limit
);

export const selectSortOption = createSelector(
  [selectOrderReducer],
  (order) => order.sortOption
);

const filterByDate = (orders: OrderItem[], timeFilter: string) => {
  if (!orders) return null;

  const now = new Date();
  let filterDate = new Date();

  switch (timeFilter) {
    case "12_months":
      filterDate.setMonth(filterDate.getMonth() - 12);
      break;
    case "30_days":
      filterDate.setDate(filterDate.getDate() - 30);
      break;
    case "7_days":
      filterDate.setDate(filterDate.getDate() - 7);
      break;
    case "24_hours":
      filterDate.setDate(filterDate.getDate() - 1);
      break;

    default:
      return orders;
  }

  return orders.filter((order) => {
    const orderDate = new Date(order.created_at);
    return orderDate >= filterDate && orderDate <= now;
  });
};

const sortOrders = (orders: OrderItem[] | null, sortOption: string) => {
  if (!orders) return null;

  const sorted = [...orders];
  switch (sortOption) {
    case "date_asc":
      return sorted.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    case "date_desc":
      return sorted.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    case "amount_asc":
      return sorted.sort((a, b) => a.price - b.price);
    case "amount_desc":
      return sorted.sort((a, b) => b.price - a.price);
    default:
      return sorted;
  }
};

export const selectFilteredOrders = createSelector(
  [selectOrderReducer],
  (order) => {
    let filtered = order.orders;

    // Apply search filter
    if (order.searchValue) {
      filtered =
        filtered?.filter((o) =>
          o.code.toLowerCase().includes(order.searchValue.toLowerCase())
        ) || null;
    }
    // Apply date filter
    filtered = filterByDate(filtered!, order.timeFilter);

    // Apply sorting
    filtered = sortOrders(filtered, order.sortOption);

    return filtered;
  }
);
export const selectOrderPendingCount = createSelector(
  [selectOrderReducer],
  (order) => order.orders?.filter((o) => o.status === "pending").length ?? 0
);

export const selectOrderProcessingCount = createSelector(
  [selectOrderReducer],
  (order) => order.orders?.filter((o) => o.status === "processing").length ?? 0
);
