import { createAction } from "utils/reducer/reducer.utils";
import { ORDER_ACTION_TYPES } from "./order.types";
import { OrderItem } from "utils/types/order";

export const fetchOrdersStart = () =>
  createAction(ORDER_ACTION_TYPES.FETCH_ORDERS_START);

export const fetchOrdersSuccess = (orders: OrderItem[]) =>
  createAction(ORDER_ACTION_TYPES.FETCH_ORDERS_SUCCESS, orders);

export const fetchOrdersFailed = (error: Error) =>
  createAction(ORDER_ACTION_TYPES.FETCH_ORDERS_FAILED, error);

export const setOrders = (orders: OrderItem[]) =>
  createAction(ORDER_ACTION_TYPES.SET_ORDERS, orders);

export const setOrderSearchValue = (searchValue: string) =>
  createAction(ORDER_ACTION_TYPES.SET_SEARCH_VALUE, searchValue);

export const setOrderDateRange = (dateRange: {
  from: Date | null;
  to: Date | null;
}) => createAction(ORDER_ACTION_TYPES.SET_DATE_RANGE, dateRange);

export const setOrderTimeFilter = (timeFilter: string) =>
  createAction(ORDER_ACTION_TYPES.SET_TIME_FILTER, timeFilter);

export const setOrderSortOption = (sortOption: string) =>
  createAction(ORDER_ACTION_TYPES.SET_SORT_OPTION, sortOption);

export const setOrderSelectedKeys = (selectedKeys: number[]) =>
  createAction(ORDER_ACTION_TYPES.SET_SELECTED_KEYS, selectedKeys);

export const setOrderLimit = (limit: number) =>
  createAction(ORDER_ACTION_TYPES.SET_LIMIT, limit);
